/*
 * File: text-viewer.style.scss
 * Project: aiscaler-web
 * File Created: Tuesday, 26th October 2021 2:24:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

::selection {
  background: #B4D5FE;
}
