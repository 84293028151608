/*
 * File: select.css
 * Project: app-aiscaler-web
 * File Created: Wednesday, 1st December 2021 4:24:04 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

.vb-select-pagination__control {
  border: none !important;
  background-color: #f3f7f9 !important;
  height: 2rem !important;
  gap: 0.5rem !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  min-height: 0 !important;
}

.vb-select-pagination__value-container {
  padding: 0 !important;
}

.vb-select-pagination__indicator-separator {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.vb-select-pagination__indicator {
  padding: 0 !important;
}

.vb-select__menu-portal {
  z-index: 1301 !important;
}

.vb-select__control {
  gap: 0.5rem !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  min-height: 0 !important;
}
.vb-select__control--is-disabled {
  background-color: #fafafa !important;
}

.vb-select__value-container {
  padding: 0 !important;
}

.vb-select__indicator-separator {
  display: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.vb-select__indicator {
  padding: 0 !important;
}

.vb-select__multi-value {
  background-color: var(--color-primary-100) !important;
  padding: 0.125rem 0.375rem;
  border-radius: 0.25rem !important;
}

.vb-select__multi-value__remove:hover {
  background-color: var(--color-primary-400) !important;
  color: white !important;
}
