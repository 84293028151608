/*
 * File: animation.style.scss
 * Project: app-aiscaler-web
 * File Created: Friday, 19th November 2021 9:34:45 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

.animate-fade-in-up {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1rem, 0);
    transform: translate3d(0, 1rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1rem, 0);
    transform: translate3d(0, 1rem, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate-fade-in {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-zoom-in-up {
  -webkit-animation-duration: 150ms;
  animation-duration: 150ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1rem, 0) scale(0);
    transform: translate3d(0, 1rem, 0) scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 1rem, 0) scale(0);
    transform: translate3d(0, 1rem, 0) scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #000;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(60, 60, 60, 0) 0,
    rgba(60, 60, 60, 0.2) 20%,
    rgba(60, 60, 60, 0.5) 60%,
    rgba(60, 60, 60, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
