/*
 * File: batch-label-grid.style.css
 * Project: app-aiscaler-web
 * File Created: Wednesday, 13th October 2021 11:30:59 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

 .label-row {
  .handle {
    visibility: hidden;
  }
}
.label-row:hover {
  .handle {
    visibility: visible;
  }
}
