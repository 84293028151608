/*
 * File: data-grid.css
 * Project: app-aiscaler-web
 * File Created: Thursday, 2nd December 2021 9:33:28 am
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

.MuiDataGrid-columnHeader {
  outline: none !important;
  border: none !important;
}

.MuiDataGrid-main {
  font-family: "Mulish";
  font-size: "12px";
  color: #53616d;
}

.react-datepicker__input-container input {
  width: 100% !important;
}

.vb-table {
  border-collapse: collapse;
}

.vb-table thead:after {
  content: "_";
  display: block;
  line-height: 0.75rem;
  color: transparent;
}

.thead-no-spacing:after {
  content: "" !important;
  display: block;
  line-height: 0 !important;
  color: transparent;
}

.vb-table tbody {
  background-color: white;
}

.vb-table tbody tr:not(:first-child) {
  border-top: solid 1px #d1dbdf;
}
