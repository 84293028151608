/*
 * File: index.css
 * Project: app-aiscaler-web
 * File Created: Thursday, 29th July 2021 9:33:25 am
 * Author: Pham Dinh Anh (v.anhphd@vinbrain.net)
 *
 * Copyright 2021 VinBrain JSC
 */

@import "../assets/fonts/uicons-regular-rounded/uicons-regular-rounded.css";
@import "../assets/fonts/dicom-editor/dicom-editor-tools.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../styles/animations/animation.css";
@import "./tailwind/button.css";
@import "./select/select.css";
@import "./grid/data-grid.css";
@import "./theme/theme.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --animate-duration: 0.2s;
  --color-accent-50: #fdf5f3;
  --color-accent-100: #ffe1d4;
  --color-accent-200: #ffc3ab;
  --color-accent-300: #f4927c;
  --color-accent-400: #ff7d59;
  --color-accent-500: #ff5630;
  --color-accent-600: #da4928;
  --color-accent-700: #b13317;
  --color-accent-800: #8c1106;
  --color-accent-900: #660803;

  --color-background-0: #ffffff;
  --color-background-50: #f9fafc;
  --color-background-100: #f3f7f9;
  --color-background-200: #eaf1f4;
  --color-background-300: #d1dbdf;
  --color-background-400: #888c96;
  --color-background-500: #8e9aab;
  --color-background-600: #677385;
  --color-background-700: #53616d;
  --color-background-800: #23262f;
  --color-background-900: #1e1f20;

  --color-error-50: #fff2f0;
  --color-error-100: #ffedeb;
  --color-error-200: #ffc8c2;
  --color-error-300: #ffa099;
  --color-error-400: #ff7570;
  --color-error-500: #ff4747;
  --color-error-600: #d93237;
  --color-error-700: #b3202a;
  --color-error-800: #8c121e;
  --color-error-900: #660c18;

  --color-secondary-50: #fafeff;
  --color-secondary-100: #edf8f9;
  --color-secondary-200: #e1f4f6;
  --color-secondary-300: #d4edef;
  --color-secondary-400: #42d8e3;
  --color-secondary-500: #1cc3d6;
  --color-secondary-600: #17b2c4;
  --color-secondary-700: #0c91a0;
  --color-secondary-800: #005063;
  --color-secondary-900: #002f3d;

  --color-primary: #3e7df8;
  --color-primary-50: #f4f8ff;
  --color-primary-100: #e3f0ff;
  --color-primary-200: #bad8ff;
  --color-primary-300: #91bdff;
  --color-primary-400: #7aa1ef;
  --color-primary-500: #3e7df8;
  --color-primary-600: #2763d7;
  --color-primary-700: #104bbc;
  --color-primary-800: #0d2985;
  --color-primary-900: #081a5e;

  --color-success-50: #edfff3;
  --color-success-100: #c0fad6;
  --color-success-200: #91edb7;
  --color-success-300: #65e09d;
  --color-success-400: #3dd486;
  --color-success-500: #1ac774;
  --color-success-600: #0da15e;
  --color-success-700: #047a49;
  --color-success-800: #005434;
  --color-success-900: #002e1e;

  --color-warning-50: #fff7e6;
  --color-warning-100: #ffe0a3;
  --color-warning-200: #ffce7a;
  --color-warning-300: #ffba52;
  --color-warning-400: #f79d28;
  --color-warning-500: #ea7e00;
  --color-warning-600: #c46200;
  --color-warning-700: #9e4a00;
  --color-warning-800: #783400;
  --color-warning-900: #522100;
}

body {
  background-color: white;
}

html {
  font-family: "Mulish", sans-serif;
  color: #53616d;
}

#tooltip-root {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
}

button:focus {
  outline: none;
}

.parent > .child-on-hover {
  visibility: hidden;
}

.parent:hover > .child-on-hover {
  visibility: visible;
}
.bg-black-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.1) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}

.dark-scrollbar {
  scrollbar-color: #1e293b;
  scrollbar-width: thin;
}

.dark-scrollbar::-webkit-scrollbar-track {
  background-color: #1e293b;
}

.dark-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #1e293b;
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background-color: #475569;
  border-radius: 10px;
}

.light-scrollbar {
  scrollbar-color: #f5f5f5;
  scrollbar-width: thin;
}

.light-scrollbar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.light-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.light-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 10px;
}

.svg-path:hover {
  stroke: "#FF00FF" !important;
  cursor: move !important;
}
#svg-root {
  position: absolute;
  z-index: -1;
  left: 0;
}

/* Chrome, Safari, Edge, Opera */
.input-clean::-webkit-outer-spin-button,
.input-clean::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-clean[type="number"] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

/* animations */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.grIcon path {
  stroke: currentColor;
}
