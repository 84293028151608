/*
 * File: button.css
 * Project: app-aiscaler-web
 * File Created: Wednesday, 24th November 2021 2:55:59 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2021 VinBrain JSC
 */

@layer components {
  .button-warn-outlined {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-background-900 text-warning-500 border border-warning-500;
  }
  .button-error {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-error-500 text-white border border-error-500;
  }
  .button-warn-secondary {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-warning-50 text-warning-500 border border-warning-50;
  }
  .button-warn {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-warning-500 text-white border border-warning-500;
  }
  .button-primary {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded bg-primary text-white border border-primary;
  }
  .button-text-primary {
    @apply flex items-center justify-center px-3 text-white rounded h-9 bg-primary;
    min-width: 114px;
  }
  .button-secondary {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded border border-primary text-primary bg-white;
  }
  .button-secondary-gray {
    @apply flex items-center justify-center gap-1.5 px-3 h-9 rounded border border-gray-400 text-gray-400 bg-white;
  }
  .button-tertiary {
    @apply flex items-center justify-center text-primary;
  }
  .button-text-secondary {
    @apply flex items-center justify-center px-3 bg-white border rounded h-9 border-primary text-primary;
    min-width: 114px;
  }
}
