/*
 * File: theme.css
 * Project: app-aiscaler-web
 * File Created: Monday, 21st February 2022 3:25:00 pm
 * Author: v.anhphamd (v.anhphd@vinbrain.net)
 * 
 * Copyright 2022 VinBrain JSC
 */
.image-labeling-theme {
  --color-primary: #ea7e00;
}
.model-web-theme-1 {
  --color-primary: #3e7df8;
}
.model-web-theme-2 {
  --color-primary: #1cc3d6;
}
.model-web-theme-3 {
  --color-primary: #ff5630;
}
.model-web-theme-4 {
  --color-primary: #ea7e00;
}
.model-web-theme-5 {
  --color-primary: #1ac774;
}
.model-web-theme-6 {
  --color-primary: #ff4747;
}
