.project-workflow-step-view {
  .vb-rich-text-editor {
    .ck-editor__top {
      height: 0;
    }

    .ck-content.ck-editor__editable {
      padding: 0;
      border: 0;
      background-color: white;
    }
  }
}

.project-workflow-step-view--first {
  .vb-rich-text-editor {
    .ck-content.ck-editor__editable {
      background-color: #eaf1f4;
    }
  }
}
