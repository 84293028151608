@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot?dcdw6g");
  src: url("./icomoon.eot?dcdw6g#iefix") format("embedded-opentype"),
    url("./icomoon.ttf?dcdw6g") format("truetype"),
    url("./icomoon.woff?dcdw6g") format("woff"),
    url("./icomoon.svg?dcdw6g#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-border_horizontal:before {
  content: "\e909";
}
.icon-border_vertical:before {
  content: "\e90a";
}
.icon-rotate_90_degrees_ccw:before {
  content: "\e90f";
}
.icon-crop_rotate:before {
  content: "\e910";
}
.icon-angle:before {
  content: "\e900";
}
.icon-circle:before {
  content: "\e902";
}
.icon-disc:before {
  content: "\e903";
}
.icon-menu:before {
  content: "\e904";
}
.icon-more-horizontal:before {
  content: "\e905";
}
.icon-more-vertical:before {
  content: "\e906";
}
.icon-move:before {
  content: "\e907";
}
.icon-rotate-cw:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e90b";
}
.icon-square:before {
  content: "\e90c";
}
.icon-trash-2:before {
  content: "\e90d";
}
.icon-triangle:before {
  content: "\e90e";
}
.icon-contrast:before {
  content: "\e9d5";
}
.icon-brightness-contrast:before {
  content: "\e9d6";
}
